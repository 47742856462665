import React from 'react';
import abimg from '../../images/wp/wp-3.png';

const About2 = (props) => {

    return (
        <section className="about-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="about-image mr-50 rmr-0">
                            <div className="experience-year">
                                <span className="number">19</span>
                                <h4>Years of Excellence</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="wp-content about-content mb-10 ml-50 pt-100 rmb-50">
                            <div className="section-title mb-10">
                                <h6>Welcome to ERE CARE Management & Maintenance</h6>
                                <h2>Refined Property Care with <br />
                                    <span>Unwavering Dedication</span>
                                </h2>
                                <p>Nestled in the heart of Flagler County, our esteemed establishment has been a beacon of excellence for over 19 illustrious years. As guardians of your property's integrity, we stand ready to serve with grace and precision.</p>
                                <p>Embark on a journey of unparalleled sophistication as you explore our array of bespoke services, meticulously crafted to meet your every need. From the lush landscapes of your dreams to the exquisite transformations of your home, our artisans execute each task with finesse and expertise.</p>
                                <p>Indulge in the seamless convenience of our renowned motto: "One call does it all." Within moments, your concerns are met with swift resolution, leaving you to revel in the tranquility of knowing that your property is in the most capable hands.</p>
                                <p>Elevate your property care experience with ERE CARE Management & Maintenance. Contact us today to embark on a journey of refinement and distinction. Your property deserves nothing less than the extraordinary, and with ERE CARE, the extraordinary is our standard.</p>
                                <h6>Contact Information</h6>
                                <p>ERE CARE Management & Maintenance License Number: 18818 & 18819</p>
                                <p>Palm Coast, FL 32137</p>
                                <p>Florida LLC: L21000275137</p>
                                <p>Email: erecapital@gmail.com</p>
                                <p>Business ID: 22575</p>
                                <p>Phone: 386-237-2856</p>
                            </div>
                            <img src={abimg} alt="About Us" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About2;
