import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage from '../HomePage/HomePage';
import HomePage2 from '../HomePage2/HomePage2';
import AboutPage from '../AboutPage/AboutPage';
import ServicePage from '../ServicePage/ServicePage';
import ServiceSinglePage from '../ServiceSinglePage/ServiceSinglePage';
import ProjectPage from '../ProjectPage/ProjectPage';
import ProjectSinglePage from '../ProjectSinglePage/ProjectSinglePage';
import BlogPage from '../BlogPage/BlogPage';
import BlogDetails from '../BlogDetails/BlogDetails';
import ContactPage from '../ContactPage/ContactPage';
import ContactSection from '../../components/ContactSection/ContactSection';
import TeamSection from '../../components/TeamSection/TeamSection';
import ReviewsSection from '../../components/Reviews/reviews';
import CareerPage from '../../components/Careers/careers';
import Gallery from '../../components/Gallery/gallery';
// import PartnerPage from '../../components/partner/partner';

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="property" element={<ProjectPage />} />
          <Route path="project-single/:slug" element={<ProjectSinglePage />} />
          <Route path="services" element={<ServicePage />} />
          <Route path="service-single/:slug" element={<ServiceSinglePage />} />
          <Route path="careers" element={<CareerPage />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="contact-section" element={<ContactSection />} />
          <Route path="company-story" element={<TeamSection />} />
          <Route path="reviews" element={<ReviewsSection />} />
          {/* <Route path="partners" element={<PartnerPage />} /> */}

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AllRoute;
