import React from 'react'
import Projects from '../../api/Projects'
import { Link } from 'react-router-dom'


const services = [
    {
        title: "POOL CARE",
        items: ["Maintenance", "Resurfacing", "Design", "Motor Repair", "Pump Installation"]
    },
    {
        title: "LAWN CARE",
        items: ["Lawn Maintenance", "Gardening", "Landscaping", "Irrigation", "Insecticide", "Herbicide", "Fertilizing"]
    },
    {
        title: "RENOVATION",
        items: ["Flooring", "Cabinets", "Painting", "Bathrooms", "Kitchens", "Carpentry"]
    },
    {
        title: "CONSTRUCTION",
        items: ["Dry Wall", "Insulation", "Soffit & Fascia", "Stucco", "Concrete", "Screen Enclosures"]
    },
    {
        title: "LANDSCAPING",
        items: ["Custom Design", "Rocks, Mulch & Sod", "Tree Cutting", "Plants", "Pruning", "Pavers", "Wells", "Lighting", "Fences", "Pressure Washing"]
    },
    {
        title: "SITE SERVICES",
        items: ["Skid Steers", "Dump Trucks", "Dump Trailers", "Logistics", "John Deere Tractor", "Lot Clearing", "Demolition", "Land Grading", "Land Mulching"]
    },
    {
        title: "CLEANING",
        items: ["Residential Homes", "Commercial Properties", "Offices & HOA Common Areas"]
    }
];

const ServicesSection = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="apartment-section text-center mb-155" style={{ paddingTop: '100px' }}>
            <div className="container">
                <div className="section-title mb-75">
                    <h2 style={{ color: '#DAA520' }}>How We Can <span className="thin" style={{ color: '#FFD700' }}>Serve</span></h2>
                </div>
                <div className="apartment-carousel">
                    <div className="row">
                        {services.map((service, i) => (
                            <div className="col col-lg-4 col-md-6 col-12" key={i}>
                                <div className="apartment-item">
                                    <div className="apartment-content">
                                        <h3 style={{ color: '#DAA520' }}>{service.title}</h3>
                                        <ul>
                                            {service.items.map((item, j) => (
                                                <li key={j}>{item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </section>
    );
}

export default ServicesSection;