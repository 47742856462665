import React, { useEffect } from 'react';
import Footer from '../../components/footer/Footer';
import sIcon1 from '../../images/contact/icon1.png';
import sIcon2 from '../../images/contact/icon2.png';
import sIcon3 from '../../images/contact/icon3.png';

const ContactPage = () => {
    useEffect(() => {
        const targetDiv = document.getElementById('67529df1-15c6-4c02-95ed-91f7601858bd');
        if (targetDiv) {
            const script = document.createElement('script');
            script.src = "https://d3ey4dbjkt2f6s.cloudfront.net/assets/static_link/work_request_embed_snippet.js";
            script.async = true;
            script.setAttribute("clienthub_id", "67529df1-15c6-4c02-95ed-91f7601858bd");
            script.setAttribute("form_url", "https://clienthub.getjobber.com/client_hubs/67529df1-15c6-4c02-95ed-91f7601858bd/public/work_request/embedded_work_request_form");
            document.body.appendChild(script);
            
            return () => {
                document.body.removeChild(script);
            };
        }
    }, []);

    const pageStyle = {
        fontFamily: 'Arial, sans-serif',
        color: '#333',
        backgroundColor: '#f4f4f9',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '20px 0'
    };

    const contactInfoStyle = {
        textAlign: 'center',
        width: '100%',
        maxWidth: '1200px',
        margin: '20px auto',
        padding: '0 15px',
        backgroundColor: '#ffffff',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        padding: '20px'
    };

    const rowStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '20px',
    };

    const infoItemStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        textAlign: 'left',
        fontSize: '1.1em',
    };

    const iconStyle = {
        width: '50px',
    };

    const formContainerStyle = {
        width: '90%', // Make it take up slightly less than full width
        padding: '15px', // Reduce padding
        maxWidth: '800px', // Reduce the maximum width of the container
        margin: '20px auto',
        backgroundColor: '#ffffff', 
        boxShadow: '0 0 15px rgba(0,0,0,0.1)', 
        borderRadius: '8px', 
        overflow: 'visible', 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'auto',
        marginBottom: '40px', // Adds space below the container
    };
    
    return (
        <div style={pageStyle}>
            <div style={contactInfoStyle}>
                <div style={rowStyle}>
                    <div style={infoItemStyle}>
                        <img src={sIcon1} alt="Location Icon" style={iconStyle} />
                        <p>1 Hargrove Grde Ste 1K, Palm Coast, FL 32137</p>
                    </div>
                    <div style={infoItemStyle}>
                        <img src={sIcon2} alt="Email Icon" style={iconStyle} />
                        <p>erecapital@gmail.com<br/>386-237-2856</p>
                    </div>
                    <div style={infoItemStyle}>
                        <img src={sIcon3} alt="Website Icon" style={iconStyle} />
                        <p>Web Address: <br/>erecare.com</p>
                    </div>
                </div>
            </div>
            <div id="67529df1-15c6-4c02-95ed-91f7601858bd" style={formContainerStyle}></div>
            <Footer />
        </div>
    );
}

export default ContactPage;
