import React, { useState } from 'react';
import Navbar from '../../components/header';  // Adjust the path as necessary
import Footer from '../../components/footer/Footer';  // Adjust the path as necessary
import Modal from 'react-modal';

const images = [
    `${process.env.PUBLIC_URL}/images/image1.jpg`,
    `${process.env.PUBLIC_URL}/images/image2.jpg`,
    `${process.env.PUBLIC_URL}/images/image3.jpg`,
    `${process.env.PUBLIC_URL}/images/image4.jpg`,
    `${process.env.PUBLIC_URL}/images/image5.jpg`,
    `${process.env.PUBLIC_URL}/images/image6.jpg`
];

const Gallery = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const openModal = (image) => {
        console.log("Opening modal for image: ", image);
        setSelectedImage(image);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setSelectedImage('');
    };

    return (
        <>
            <Navbar />
            <section style={styles.gallerySection}>
                <div className="container" style={styles.container}>
                    <div style={styles.sectionTitle}>
                        <h2 style={styles.sectionMainTitle}>Our Gallery</h2>
                        <p style={styles.sectionDescription}>Take a look at some of our work and the wonderful results we achieve for our clients.</p>
                    </div>
                    <div className="row">
                        {images.map((image, index) => (
                            <div className="col-lg-4 col-md-6 mb-4" key={index} style={styles.imageWrapper}>
                                <img
                                    src={image}
                                    alt={`Gallery ${index + 1}`}
                                    style={styles.image}
                                    onClick={() => openModal(image)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={modalStyles}
                contentLabel="Image Lightbox"
            >
                <img src={selectedImage} alt="Selected" style={styles.modalImage} />
                <button onClick={closeModal} style={styles.closeButton}>Close</button>
            </Modal>
            <Footer />
        </>
    );
}

const styles = {
    gallerySection: {
        padding: '150px 0 100px 0',
        backgroundColor: '#f8f9fa'
    },
    container: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: '15px',
        padding: '20px',
        paddingTop: '150px'
    },
    sectionTitle: {
        marginBottom: '35px',
        textAlign: 'center',
        color: '#333'
    },
    sectionMainTitle: {
        color: '#333',
        marginTop: '10px'
    },
    sectionDescription: {
        color: '#666',
        marginTop: '20px'
    },
    imageWrapper: {
        padding: '10px',
        borderRadius: '10px',
        overflow: 'hidden'
    },
    image: {
        width: '100%',
        height: 'auto',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer'
    },
    modalImage: {
        width: '100%',
        height: 'auto',
        borderRadius: '10px'
    },
    closeButton: {
        marginTop: '10px',
        padding: '10px 20px',
        backgroundColor: '#AA7E39',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    }
};

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '10px',
        padding: '20px'
    }
};

export default Gallery;
