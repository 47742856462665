import React, { Fragment, useState, useEffect } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom";

const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/',
    },
    {
        id: 2,
        title: 'Services',
        link: '/services',
    },
    {
        id: 3,
        title: 'About Us',
        link: '#',
        submenu: [
            {
                id: 31,
                title: 'Company Story',
                link: '/company-story',
            },
            {
                id: 32,
                title: 'Projects',
                link: '/gallery',
            },
            {
                id: 33,
                title: 'Reviews',
                link: '/reviews',
            },
            {
                id: 34,
                title: 'Careers',
                link: '/careers',
            },
        ],
    },
    {
        id: 4,
        title: 'Contact',
        link: '/contact',
    }
];

const MobileMenu = () => {
    const [openId, setOpenId] = useState(null);
    const [menuActive, setMenuState] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    const styles = {
        mobileMenu: {
            display: menuActive ? 'block' : 'none',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#fff',
            zIndex: 9999,
            overflowY: 'auto',
            color: '#333',
        },
        menuClose: {
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '10px 20px',
            backgroundColor: '#333',
            color: '#fff',
        },
        close: {
            cursor: 'pointer',
        },
        responsiveMenu: {
            listStyle: 'none',
            padding: 0,
            margin: 0,
        },
        active: {
            backgroundColor: '#f1f1f1',
        },
        subMenu: {
            paddingLeft: '20px',
            display: 'block',
        },
        navbarHeader: {
            position: 'relative',
            backgroundColor: '#333',
            color: '#fff',
        },
        navbarToggle: {
            border: 'none',
            background: 'transparent',
            cursor: 'pointer',
            display: 'inline-block',
            padding: '10px 15px',
        },
        iconBar: {
            display: 'block',
            width: '22px',
            height: '2px',
            margin: '4px auto',
            backgroundColor: '#FFD700',
        },
        angleIcon: {
            marginLeft: '5px',
            color: '#FFD700',
        },
        link: {
            color: '#000',
            fontWeight: 'bold',
            textDecoration: 'none',
        },
        menuItemText: {
            color: '#000',
            fontWeight: 'bold',
            cursor: 'pointer',
        },
        submenuItem: {
            paddingLeft: '20px',
            display: 'block',
        },
        listItem: {
            display: 'block',
            padding: '10px 0px',
        },
    };

    if (!isMobile) {
        return null;
    }

    return (
        <div>
            <div style={styles.mobileMenu}>
                <div style={styles.menuClose}>
                    <div style={styles.close} onClick={() => setMenuState(!menuActive)}>
                        <i className="ti-close"></i>
                    </div>
                </div>

                <ul style={styles.responsiveMenu}>
                    {menus.map((item, mn) => (
                        <ListItem 
                            key={mn} 
                            style={{
                                ...styles.listItem, 
                                ...(item.id === openId ? styles.active : null)
                            }}
                        >
                            {item.submenu ? (
                                <Fragment>
                                    <p onClick={() => setOpenId(item.id === openId ? null : item.id)} style={styles.menuItemText}>
                                        {item.title}
                                        <i className={item.id === openId ? 'fa fa-angle-up' : 'fa fa-angle-down'} style={styles.angleIcon}></i>
                                    </p>
                                    <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                                        <List style={styles.subMenu}>
                                            {item.submenu.map((submenu, i) => (
                                                <ListItem key={i} style={styles.submenuItem}>
                                                    <Link onClick={ClickHandler} to={submenu.link} style={styles.link}>
                                                        {submenu.title}
                                                    </Link>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                </Fragment>
                            ) : (
                                <Link onClick={ClickHandler} to={item.link} style={styles.link}>
                                    {item.title}
                                </Link>
                            )}
                        </ListItem>
                    ))}
                </ul>
            </div>

            <div style={styles.navbarHeader}>
                <button type="button" style={styles.navbarToggle} onClick={() => setMenuState(!menuActive)}>
                    <span style={styles.iconBar}></span>
                    <span style={styles.iconBar}></span>
                    <span style={styles.iconBar}></span>
                </button>
            </div>
        </div>
    );
};

export default MobileMenu;