import React from 'react';
import Navbar from '../../components/header';
import Footer from '../../components/footer/Footer';
import Bg from '../../images/team/team-bg.png';
import team1 from '../../images/team/team1.png';
import team2 from '../../images/team/team2.png';
import team3 from '../../images/team/team3.png';

const TeamSection = (props) => {
    return (
        <>
            <Navbar />
            <div style={{ height: '80px' }}></div>
            <section className="team-section mb-240" style={{ backgroundImage: `url(${Bg})` }}>
                <div className="team-wrap">
                    <div className="container">
                        <div className="section-title text-white text-center mb-55">
                            <h2>Welcome to <span className="thin">Epic Real Estate Care Management & Maintenance</span></h2>
                            <h6>Meet Our Team</h6>
                        </div>
                        <div className="company-story text-white mb-55">
                            <p><strong>Welcome to Epic Real Estate Care Management & Maintenance</strong>, your trusted partner in property care and maintenance for over 19 years, proudly serving the vibrant community of Flagler. Rooted in our passion for our county and its residents, we are dedicated to upholding the highest standards of honesty, integrity, and quality craftsmanship in all that we do.</p>
                            <p>As pioneers in property care, we've made it our mission to not just meet but exceed expectations, seamlessly fitting into your home owning or rental needs. Our unwavering commitment to excellence, craftsmanship, and community involvement has earned us a loyal clientele base, enabling us to grow alongside the dynamic pace of Palm Coast.</p>
                            <p>At Epic Real Estate Care Management & Maintenance, we recognize the value of a single, reliable point of contact for all your property needs. With our motto, <strong>"One call does it all,"</strong> we simplify the process, ensuring efficiency and peace of mind for every project.</p>
                            <ul>
                                <li><strong>Landscaping:</strong> From lawn maintenance to landscape installation, we ensure your outdoor spaces are not just maintained but transformed into beautiful retreats.</li>
                                <li><strong>Construction and Home Renovations:</strong> Whether it's a bathroom remodel, kitchen renovation, or property construction, our expert team delivers exceptional results tailored to your vision.</li>
                                <li><strong>Painting and Flooring:</strong> From exterior to interior painting, to tile and wood floor installation, we enhance the aesthetics of your property with precision and care.</li>
                                <li><strong>Pool Services:</strong> From construction and maintenance to pool surfacing, we ensure your pool area is a pristine oasis for relaxation and enjoyment.</li>
                                <li><strong>Site Services and Heavy Machinery:</strong> With our fleet of Skid Steers, John Deere Tractors, Dump Trailers, Trailer Buckets, Heavy-duty trucks, and trailers, we offer comprehensive site services for all your property needs.</li>
                                <li><strong>Drywall, Stucco, and Texturing:</strong> Our skilled craftsmen handle drywall installation, stucco work, and texturing to perfect your property's interior and exterior.</li>
                                <li><strong>Property Management and Renovation:</strong> We offer comprehensive property management services and renovation solutions to ensure your property is well-maintained and updated.</li>
                            </ul>
                            <p><strong>ERE CARE Management & Maintenance License Number:</strong> 18818 & 18819<br />
                               <strong>Address:</strong> 1 Hargrove Grde Ste 1K, Palm Coast, FL 32137 LLC: L21000275137<br />
                               <strong>Email:</strong> erecapital@gmail.com<br />
                               <strong>Business ID:</strong> 22575<br />
                               <strong>Phone:</strong> 386-237-2856</p>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="team-item">
                                    <img src={team1} alt="Pablo Torres"/>
                                    <div className="team-des">
                                        <h3>Pablo Torres</h3>
                                        <span>President</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="team-item">
                                    <img src={team2} alt="Luis Torres"/>
                                    <div className="team-des">
                                        <h3>Luis Torres</h3>
                                        <span>Owner</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="team-item">
                                    <img src={team3} alt="Shane Dennis"/>
                                    <div className="team-des">
                                        <h3>Shane Dennis</h3>
                                        <span>Partner Business Development</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="team-item">
                                    <img src={team1} alt="Wyatt Dennis"/>
                                    <div className="team-des">
                                        <h3>Wyatt Dennis</h3>
                                        <span>Partner Site Services</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="team-item">
                                    <img src={team2} alt="Matt DiBiaso"/>
                                    <div className="team-des">
                                        <h3>Matt DiBiaso</h3>
                                        <span>Chief Technical Officer</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="team-item">
                                    <img src={team3} alt="Brian Muller"/>
                                    <div className="team-des">
                                        <h3>Brian Muller</h3>
                                        <span>Division Director</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="team-item">
                                    <img src={team1} alt="Brenda Astacio"/>
                                    <div className="team-des">
                                        <h3>Brenda Astacio</h3>
                                        <span>Accountant</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="team-item">
                                    <img src={team2} alt="Abizael Ortiz"/>
                                    <div className="team-des">
                                        <h3>Abizael Ortiz</h3>
                                        <span>Jr. Accountant</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="team-item">
                                    <img src={team3} alt="Clayton Dennis"/>
                                    <div className="team-des">
                                        <h3>Clayton Dennis</h3>
                                        <span>Foreman</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="team-item">
                                    <img src={team1} alt="Andres Suarez"/>
                                    <div className="team-des">
                                        <h3>Andres Suarez</h3>
                                        <span>Branding Strategist</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="team-item">
                                    <img src={team2} alt="David Acevedo"/>
                                    <div className="team-des">
                                        <h3>David Acevedo</h3>
                                        <span>Marketing Strategist</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default TeamSection;
