import React from 'react';

const WeatherSection = () => {
    // Styles for the section
    const sectionStyle = {
        backgroundImage: `url('https://example.com/your/background/image.jpg')`,
        padding: '50px 0',
        textAlign: 'center',
    };

    // Styles for the iframe container
    const iframeContainerStyle = {
        backgroundColor: '#fff',
        padding: '20px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        borderRadius: '10px',
        maxWidth: '90%', // Allow container to scale responsively
        margin: '0 auto', // Center the iframe container within the section
    };

    // Styles for the iframe itself
    const iframeStyle = {
        width: '100%', // Make the iframe responsive
        height: '0',
        paddingBottom: '56.25%', // 16:9 Aspect Ratio
        position: 'relative',
        border: 'none',
    };

    const iframeInnerStyle = {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
    };

    // The iframe source URL
    const iframeSrc = "https://embed.windy.com/embed.html?type=map&location=coordinates&metricRain=default&metricTemp=default&metricWind=default&zoom=5&overlay=radar&product=radar&level=surface&lat=25.483&lon=-85.869";

    return (
        <section className="weather-section" style={sectionStyle}>
            <div style={iframeContainerStyle}>
                <div style={iframeStyle}>
                    <iframe
                        style={iframeInnerStyle}
                        src={iframeSrc}
                        frameborder="0"
                        title="Windy Weather Map"
                        allowfullscreen>
                    </iframe>
                </div>
            </div>
        </section>
    );
};

export default WeatherSection;
