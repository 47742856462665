import React, { useState } from 'react';
import fImg from '../../images/faq/faq-right-bg.png';

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

const FaqSection = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <section className="faq-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 pr-125 rpr-15">
                        <div className="faq-left-image"></div>
                    </div>
                    <div className="col-lg-6 pl-0">
                        <div className="faq-accordion ras-content pt-95 pb-110 rpb-0 rmb-100">
                            <div className="section-title mb-35">
                                <h6>One Call Does It All</h6>
                                <h2>Experience Convenience, Reliability, and Peace of Mind</h2>
                                <p>Say goodbye to the hassle of coordinating multiple service providers. With ERE CARE, one call is all it takes to address all your property care needs.</p>
                            </div>
                            <div id="accordion">
                                <Accordion open={open} toggle={toggle}>
                                    <AccordionItem className='card'>
                                        <AccordionHeader targetId="1" className='card-header'>Convenience</AccordionHeader>
                                        <AccordionBody accordionId="1">
                                            Bid farewell to the complexities of managing your property care needs. At ERE CARE, we simplify the process with our signature motto: "One Call Does It All."
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem className='card'>
                                        <AccordionHeader targetId="2" className='card-header'>Reliability</AccordionHeader>
                                        <AccordionBody accordionId="2">
                                            Embrace the ease and efficiency of entrusting your property to a single, trusted partner. With us by your side, experience the convenience, reliability, and peace of mind that comes with our streamlined approach to property ownership and management.
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem className='card'>
                                        <AccordionHeader targetId="3" className='card-header'>Peace of Mind</AccordionHeader>
                                        <AccordionBody accordionId="3">
                                            Enjoy the peace of mind that comes with knowing all your property care needs are taken care of by one reliable service provider.
                                        </AccordionBody>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                        <div className="faq-right-bg">
                            <img src={fImg} alt="FAQ" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FaqSection;
