import simg1 from '../images/services/icon1.png';
import simg2 from '../images/services/icon2.png';
import simg3 from '../images/services/icon3.png';

import sSimg1 from '../images/service-single/img-1.jpg';
import sSimg2 from '../images/service-single/img-2.jpg';
import sSimg3 from '../images/service-single/img-3.jpg';

import sSingleimg1 from '../images/service-single/2.jpg';
import sSingleimg2 from '../images/service-single/3.jpg';

const Services = [
    {
        Id: '1',
        sImg: simg1,
        sSimg: sSimg1,
        sTitle: 'Landscaping',
        slug: 'Landscaping',
        description: 'From lawn maintenance to landscape installation, we ensure your outdoor spaces are not just maintained but transformed into beautiful retreats.',
        des2: 'Our team provides comprehensive landscaping services, including design, installation, and maintenance, to enhance the beauty of your property.',
        des3: 'We offer tailored landscaping solutions that meet your specific needs, ensuring your outdoor spaces are always inviting and well-maintained.',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
    },
    {
        Id: '2',
        sImg: simg2,
        sSimg: sSimg2,
        sTitle: 'Construction and Home Renovations',
        slug: 'Construction-and-Home-Renovations',
        description: 'Whether it\'s a bathroom remodel, kitchen renovation, or property construction, our expert team delivers exceptional results tailored to your vision.',
        des2: 'Our construction and renovation services include everything from initial design to final execution, ensuring your project is completed to the highest standards.',
        des3: 'With our experienced team, we handle all aspects of your renovation or construction project, delivering results that exceed your expectations.',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
    },
    {
        Id: '3',
        sImg: simg3,
        sSimg: sSimg3,
        sTitle: 'Painting and Flooring',
        slug: 'Painting-and-Flooring',
        description: 'From exterior to interior painting, to tile and wood floor installation, we enhance the aesthetics of your property with precision and care.',
        des2: 'Our painting and flooring services are designed to improve the look and feel of your property, with meticulous attention to detail.',
        des3: 'We use high-quality materials and expert techniques to ensure that your painting and flooring projects are completed to perfection.',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
    }
];

export default Services;
