import React, { useState } from 'react';
import Navbar from '../../components/header';  // Adjust the path as necessary
import Footer from '../../components/footer/Footer';  // Adjust the path as necessary
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import backgroundImage from '../../images/apartment/property-right.png'; // Adjust the path as necessary

const ReviewsSection = () => {
    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    const reviews = [
        {
            id: '1',
            author: 'John Doe',
            text: 'Fantastic service! Highly recommend.',
            detail: 'The service was beyond expectations with timely responses and professional staff.'
        },
        {
            id: '2',
            author: 'Jane Smith',
            text: 'Very pleased with the professionalism.',
            detail: 'Everything from the initial call to the service completion was handled impeccably.'
        },
        {
            id: '3',
            author: 'Emily Johnson',
            text: 'Excellent customer support.',
            detail: 'Support was always available and resolved all issues quickly.'
        },
        {
            id: '4',
            author: 'Michael Brown',
            text: 'Highly professional team.',
            detail: 'The team was knowledgeable and efficient in their work.'
        },
        {
            id: '5',
            author: 'Sarah Williams',
            text: 'Great experience!',
            detail: 'I had a great experience with their services. Highly recommended!'
        },
        {
            id: '6',
            author: 'David Wilson',
            text: 'Top-notch quality.',
            detail: 'The quality of work was outstanding, and the staff was very friendly.'
        }
    ];

    return (
        <>
            <Navbar />
            <section style={styles.reviewsSection}>
                <div className="container" style={styles.container}>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div style={styles.reviewsAccordion}>
                                <div style={styles.sectionTitle}>
                                    <h6 style={styles.sectionSubtitle}>Real Feedback from Our Customers</h6>
                                    <h2 style={styles.sectionMainTitle}>What Our Clients Say</h2>
                                    <p style={styles.sectionDescription}>Discover why our customers love our services and the difference we make in their lives.</p>
                                </div>
                                <div id="accordion">
                                    <Accordion open={open} toggle={toggle}>
                                        {reviews.map(review => (
                                            <AccordionItem key={review.id} className='card'>
                                                <AccordionHeader targetId={review.id} className='card-header' style={styles.cardHeader}>
                                                    {review.author}
                                                </AccordionHeader>
                                                <AccordionBody accordionId={review.id} style={styles.cardBody}>
                                                    <strong>{review.text}</strong><br />
                                                    {review.detail}
                                                </AccordionBody>
                                            </AccordionItem>
                                        ))}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

const styles = {
    reviewsSection: {
        padding: '150px 0 100px 0', // Added padding at the top and bottom
        backgroundColor: '#f8f9fa' // Light background color
    },
    container: {
        backgroundImage: `url(${backgroundImage})`, // Use the imported image
        backgroundSize: 'cover', // Ensure the image covers the entire container
        backgroundPosition: 'center', // Center the image
        backgroundRepeat: 'no-repeat', // Prevent the image from repeating
        borderRadius: '15px', // Add rounded corners
        padding: '20px' // Add padding inside the container
    },
    reviewsAccordion: {
        background: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
        borderRadius: '15px', // Add rounded corners
        padding: '40px' // Adjust padding for better spacing
    },
    sectionTitle: {
        marginBottom: '35px',
        textAlign: 'center',
        color: '#333' // Dark text color
    },
    sectionSubtitle: {
        color: '#AA7E39', // Dark Gold color from the logo
        fontWeight: 'bold'
    },
    sectionMainTitle: {
        color: '#333', // Dark text color
        marginTop: '10px'
    },
    sectionDescription: {
        color: '#666', // Gray text color
        marginTop: '20px'
    },
    cardHeader: {
        backgroundColor: '#AA7E39', // Dark Gold background color for the accordion headers
        color: '#fff', // White text color
        cursor: 'pointer'
    },
    cardBody: {
        backgroundColor: '#fff', // White background for the accordion body
        color: '#333' // Dark text color
    },
    '@media (max-width: 768px)': {
        reviewsSection: {
            padding: '100px 0' // Adjust padding for mobile
        }
    }
};

export default ReviewsSection;
