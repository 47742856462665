import React, { useState } from 'react';
import Navbar from '../../components/header';  // Adjust the path as necessary
import Footer from '../../components/footer/Footer';  // Adjust the path as necessary
import backgroundImage from '../../images/faq/faq-right-bg.png'; // Adjust the path as necessary

const CareerPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        position: '',
        resume: null
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: files ? files[0] : value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
        console.log('Form data submitted:', formData);
    };

    return (
        <>
            <Navbar />
            <section style={styles.careerSection}>
                <div className="container" style={styles.container}>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div style={styles.careerFormWrapper}>
                                <div style={styles.sectionTitle}>
                                    <h2 style={styles.sectionMainTitle}>Join Our Team</h2>
                                    <p style={styles.sectionDescription}>We're always looking for talented individuals to join our team. Fill out the form below to apply.</p>
                                </div>
                                <form onSubmit={handleSubmit} style={styles.form}>
                                    <div className="form-group">
                                        <label htmlFor="name" style={styles.label}>Full Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            className="form-control"
                                            style={styles.input}
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email" style={styles.label}>Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="form-control"
                                            style={styles.input}
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone" style={styles.label}>Phone Number</label>
                                        <input
                                            type="tel"
                                            id="phone"
                                            name="phone"
                                            className="form-control"
                                            style={styles.input}
                                            value={formData.phone}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="position" style={styles.label}>Position Applying For</label>
                                        <input
                                            type="text"
                                            id="position"
                                            name="position"
                                            className="form-control"
                                            style={styles.input}
                                            value={formData.position}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="resume" style={styles.label}>Upload Resume</label>
                                        <input
                                            type="file"
                                            id="resume"
                                            name="resume"
                                            className="form-control"
                                            style={styles.input}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary" style={styles.submitButton}>Submit Application</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

const styles = {
    careerSection: {
        padding: '150px 0 100px 0', // Added padding at the top and bottom
        backgroundColor: '#f8f9fa' // Light background color
    },
    container: {
        backgroundImage: `url(${backgroundImage})`, // Use the imported image
        backgroundSize: 'cover', // Ensure the image covers the entire container
        backgroundPosition: 'center', // Center the image
        backgroundRepeat: 'no-repeat', // Prevent the image from repeating
        borderRadius: '15px', // Add rounded corners
        padding: '20px' // Add padding inside the container
    },
    careerFormWrapper: {
        background: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
        borderRadius: '15px', // Add rounded corners
        padding: '40px' // Adjust padding for better spacing
    },
    sectionTitle: {
        marginBottom: '35px',
        textAlign: 'center',
        color: '#333' // Dark text color
    },
    sectionMainTitle: {
        color: '#333', // Dark text color
        marginTop: '10px'
    },
    sectionDescription: {
        color: '#666', // Gray text color
        marginTop: '20px'
    },
    label: {
        color: '#333',
        fontWeight: 'bold'
    },
    input: {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        border: '1px solid #ccc'
    },
    submitButton: {
        backgroundColor: '#AA7E39', // Dark Gold color from the logo
        color: '#fff',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    }
};

export default CareerPage;
