import React from 'react';
import { Link } from 'react-router-dom';
import Bg from '../../images/footer.png';
import fnglaLogo from '../../images/photo.png';
import ufLogo from '../../images/UF IFAS Logo.png';
import waterstarLogo from '../../images/waterstarap.png';
import fflLogo from '../../images/ffl.png';
import fnglaHorticultureLogo from '../../images/fnglahorticulture.png';
import usFlag from '../../images/usflag.png';
import sflFlag from '../../images/sflflag.png';
import alumniLogo from '../../images/alumni.png';
import CtaSection from '../CtaSection/CtaSection';

const Footer = () => {
    const ClickHandler = () => window.scrollTo(10, 0);

    const footerStyle = {
        paddingTop: '220px',
        backgroundImage: `url(${Bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
    };

    const textStyle = {
        fontSize: '14px',
        color: '#ccc',
        lineHeight: 1.6,
    };

    const headingStyle = {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#fff',
        marginBottom: '15px',
    };

    const linkStyle = {
        color: '#f8f9fa',
        textDecoration: 'underline',
    };

    const mediaQueryStyles = `
      @media (max-width: 768px) {
        .logoContainer {
          flex-wrap: wrap;
          justify-content: center;
          gap: 8px;
        }
        .logo {
          max-width: 80px;
        }
      }
    `;

    return (
        <div>
            <CtaSection />
            <footer style={footerStyle}>
                <style>{mediaQueryStyles}</style>
                <div className="container">
                    <div className="row">
                        {/* Contact Section */}
                        <div className="col-md-6">
                            <div className="footer-contact">
                                <h5 style={headingStyle}>Visit Us</h5>
                                <p style={textStyle}>1 Hargrove Grade Ste 1K, Palm Coast, FL 32137</p>
                                <h5 style={headingStyle}>Contact</h5>
                                <p style={textStyle}>
                                    Email: <a href="mailto:erecapital@gmail.com" style={linkStyle}>erecapital@gmail.com</a>
                                </p>
                                <p style={textStyle}>
                                    Call Us Now: <a href="tel:386-237-2856" style={linkStyle}>386-237-2856</a>
                                </p>
                            </div>
                        </div>

                        {/* Business Info Section */}
                        <div className="col-md-6">
                            <div className="business-info">
                                <h5 style={headingStyle}>Business Info</h5>
                                <p style={textStyle}>ERE CARE Management & Maintenance</p>
                                <p style={textStyle}>License Number: 18818 & 18819</p>
                                <p style={textStyle}>LLC: L21000275137</p>
                                <p style={textStyle}>Business ID: 22575</p>
                                <p style={textStyle}>FNGLA Member Since 2020: H9354641 & M9354641</p>
                                <p style={textStyle}>UF IFAS Member Since 2020: GV913817-1</p>
                                <p style={textStyle}>FFL Professional Since 2020: 9628420-LA0010597 PVD233</p>

                                {/* Association Descriptions
                                <div style={{ marginTop: '20px', textAlign: 'left' }}>
                                    <h5 style={headingStyle}>Endorsed by Premier Industry Associations and Certifications</h5>
                                </div>
                                <p style={textStyle}>
                                Florida Nursery, Growers, and Landscape Association (FNGLA): Since 2020, we have been honored to be affiliated with FNGLA, proudly holding both the Certified Professional Horticulture and Certified Professional Maintenance designations. This esteemed recognition underscores our unwavering commitment to horticultural excellence and our dedication to continuous professional growth.
                                </p>
                                <p style={textStyle}>
                                University of Florida Institute of Food and Agricultural Sciences (UF IFAS): As respected members since 2020, our collaboration with UF IFAS highlights our dedication to advancing sustainable landscaping practices. Through their pioneering research and educational initiatives, we proudly support and contribute to ecological stewardship, embodying the values of innovation and sustainability.
                                </p>
                                <p style={textStyle}>
                                University of Florida Alumni Association: As members of the University of Florida Alumni Association, we proudly uphold the institution's legacy of academic excellence and community leadership. This connection reinforces our commitment to continued learning and underscores our role as both business and community leaders, dedicated to applying our knowledge for the betterment of our clients and the environment.
                                </p>
                                <p style={textStyle}>
                                Florida Water Star Program: Awarded the Silver Star designation in 2023, this prestigious accolade reflects our dedication to water efficiency and sustainability. It signifies our expertise in optimizing irrigation practices and our unwavering commitment to enhancing water conservation across all properties.
                                </p>
                                <p style={textStyle}>
                                Florida-Friendly Landscaping Certified Professional (FFL): Since 2020, we have proudly maintained certification with the Florida-Friendly Landscaping program. This certification demonstrates our commitment to environmentally responsible landscaping practices, focusing on water conservation, reduced use of fertilizers and pesticides, and the preservation of Florida’s unique ecosystems.
                                </p> */}

                                {/* Logo Section */}
                                <div className="logoContainer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: '5px' }}>
                                    <img src={fnglaLogo} alt="FNGLA Logo" className="logo" style={{ maxWidth: '16%', objectFit: 'contain' }} />
                                    <img src={ufLogo} alt="UF IFAS Logo" className="logo" style={{ maxWidth: '16%', objectFit: 'contain' }} />
                                    <img src={waterstarLogo} alt="WaterStar Logo" className="logo" style={{ maxWidth: '16%', objectFit: 'contain' }} />
                                    <img src={fflLogo} alt="FFL Logo" className="logo" style={{ maxWidth: '18%', objectFit: 'contain' }} />
                                    <img src={fnglaHorticultureLogo} alt="FNGLA Horticulture Professional Logo" className="logo" style={{ maxWidth: '16%', objectFit: 'contain' }} />
                                </div>

                                {/* Flag Section */}
                                <div className="flagContainer" style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                    <img src={usFlag} alt="US Flag" style={{ maxWidth: '90px', objectFit: 'contain' }} />
                                    <img src={alumniLogo} alt="UF Alumni Logo" style={{ maxWidth: '90px', objectFit: 'contain' }} />
                                    <img src={sflFlag} alt="FL State Flag" style={{ maxWidth: '80px', objectFit: 'contain' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer Bottom */}
                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', padding: '10px 0', marginTop: '55px' }}>
                    <div className="container">
                        <p style={textStyle}>
                            © {new Date().getFullYear()} by <Link onClick={ClickHandler} to="/" style={linkStyle}>Wisemonkey Consulting LLC</Link>. All Rights Reserved.
                        </p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
