import React from 'react';

const CtaSection = (props) => {
    return (
        <section className="call-action">
            <div className="container">
                <div className="call-action-inner">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="section-title text-white rmb-35">
                                <h6>Get a Quote</h6>
                                <h2>Ready to Elevate Your Property Care Experience?</h2>
                                <p>Contact us today to learn more about our services or schedule a consultation with our team of experts.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default CtaSection;
