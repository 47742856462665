import React from 'react';
import { Link } from 'react-router-dom';
import MobileMenu from '../MobileMenu/MobileMenu';
import Logo from '../../images/logo.png';

const Header = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <header className="main-header">
            <style>{`
                /* Main header styles */
                .main-header {
                    background: #fff; /* or any other background color */
                    padding: 10px 0;
                }

                .header-top {
                    border-bottom: 1px solid #ddd;
                }

                .header-top .container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .top-inner {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }

                .logo-outer {
                    flex: 1;
                }

                .logo img {
                    max-width: 150px; /* Adjust logo size */
                }

                .header-info {
                    display: flex;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                }

                .header-info li {
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
                }

                .header-info i {
                    margin-right: 8px;
                    font-size: 18px; /* Adjust icon size */
                }

                .header-info h6 {
                    font-size: 14px; /* Adjust font size */
                    margin: 0;
                }

                .header-info span {
                    font-weight: bold;
                    margin-right: 5px;
                }

                /* Responsive Design */
                @media (max-width: 768px) {
                    .header-info {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .header-info li {
                        margin-bottom: 10px;
                    }

                    .header-info h6 {
                        font-size: 12px; /* Adjust font size on smaller screens */
                    }

                    .header-info i {
                        font-size: 16px; /* Adjust icon size on smaller screens */
                    }
                }

                .header-upper {
                    background: #f8f9fa; /* or any other background color */
                    padding: 10px 0;
                }

                .header-inner {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .nav-outer {
                    display: flex;
                    align-items: center;
                }

                /* Navbar styles */
                .main-menu {
                    margin: 0;
                    padding: 0;
                }

                .main-menu ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                }

                .main-menu li {
                    margin: 0 10px;
                }

                .main-menu a {
                    text-decoration: none;
                    color: #333; /* Adjust link color */
                    font-size: 16px; /* Adjust font size */
                }

                .main-menu .dropdown ul {
                    display: none;
                }

                .main-menu .dropdown:hover ul {
                    display: block;
                }

                .navbar-collapse {
                    display: flex;
                    justify-content: space-between;
                }
            `}</style>
            <div className="header-top">
                <div className="container">
                    <div className="top-inner">
                        <div className="logo-outer">
                            <div className="logo">
                                <Link onClick={ClickHandler} to="/">
                                    <img src={Logo} alt="Logo" />
                                </Link>
                            </div>
                        </div>
                        <ul className="header-info">
                            <li>
                                <i className="fa fa-map-marker"></i>
                                <h6><span>Address:</span> 1 Hargrove Grde Ste 1K, Palm Coast, FL 32137</h6>
                            </li>
                            <li>
                                <i className="fa fa-phone"></i>
                                <h6><span>Call Us Now:</span> 386-237-2856</h6>
                            </li>
                            {/* New "Free Estimate" list item */}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="header-upper">
                <div className="container clearfix">
                    <div className="header-inner">
                        <div className="nav-outer clearfix">
                            <nav className="main-menu navbar-expand-lg">
                                <MobileMenu/>
                                <div className="navbar-collapse collapse clearfix">
                                    <ul className="navigation clearfix">
                                        <li><Link onClick={ClickHandler} to="/">Home</Link></li>
                                        <li className="dropdown">
                                            <Link to="/services" onClick={ClickHandler}>Services</Link>
                                        </li>
                                        <li className="dropdown">
                                            <a href="#">About Us</a>
                                            <ul>
                                                <li><Link onClick={ClickHandler} to="/company-story">Company Story</Link></li>
                                                <li><Link onClick={ClickHandler} to="/gallery">Projects</Link></li>
                                                <li><Link onClick={ClickHandler} to="/reviews">Reviews</Link></li>
                                                <li><Link onClick={ClickHandler} to="/careers">Careers</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link onClick={ClickHandler} to="contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
