import React from 'react';
import Bg from '../../images/wp/wp-bg.png';
import abimg1 from '../../images/wp/wp-1.png';
import abimg2 from '../../images/wp/wp-2.png';
import abimg3 from '../../images/wp/wp-3.png';
import photo from '../../images/photo.png';

const About = (props) => {

    return (
        <section id="about" className="wp-section pt-150 rmb-100" style={{ backgroundImage: `url(${Bg})` }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="wp-images rmb-150">
                            <div className="experience-year">
                                <span className="number">19</span>
                                <h4>Years of Excellence</h4>
                            </div>
                            <img src={abimg1} alt="Wordpress" />
                            <img src={abimg2} alt="Wordpress" />
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="wp-content ml-50 rml-0 rpt-30">
                            <div className="section-title mb-10">
                                <h6>Welcome to ERE CARE Management & Maintenance</h6>
                                <h2>Refined Property Care with <br />
                                    <span>Unwavering Dedication</span>
                                </h2>
                                <p>Nestled in the heart of Flagler County, our esteemed establishment has been a beacon of excellence for over 19 illustrious years. As guardians of your property's integrity, we stand ready to serve with grace and precision.</p>
                                <p>Indulge in the seamless convenience of our renowned motto: "One call does it all." Within moments, your concerns are met with swift resolution, leaving you to revel in the tranquility of knowing that your property is in the most capable hands.</p>
                            </div>
                            <img src={abimg3} alt="About Us" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
